import { createFileRoute } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import { graphsConfig } from "@/features/graphs";

export const Route = createFileRoute(
  "/_protected/_layout/dashboard/graphs/$id"
)({
  component: Component,
  errorComponent: Error,
});

function Component() {
  const params = Route.useParams();

  if (graphsConfig[params.id]) return graphsConfig[params.id];
}

function Error() {
  const { t } = useTranslation("errors");

  return (
    <div className="p-4 text-lg font-semibold flex w-full flex-col h-full items-center justify-center shadow-md rounded-lg">
      {t("failed_loading")}
    </div>
  );
}
