import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createRouter } from "@tanstack/react-router";
import {
  QueryClient,
  QueryClientProvider,
  useQueryClient,
} from "@tanstack/react-query";
import "./i18n";
import "./index.css";

const queryClient = new QueryClient();

// Import the generated route tree
import { routeTree } from "./routeTree.gen";
import { AuthProvider, useAuth } from "./features/auth";
import { CurrencyProvider } from "./features/layouts/providers/currency-provider";
import { CountryProvider } from "./features/layouts/providers/countryProvider";
import {
  TanstackQueryDevtools,
  TanStackRouterDevtools,
  initSentry,
} from "./devtools";

// Create a new router instance
const router = createRouter({
  routeTree,
  defaultPreload: "intent",
  defaultPendingMs: 100,
  context: {
    queryClient: undefined!,
    auth: undefined!,
  },
  defaultErrorComponent: ErrorComponent,
});

initSentry(router);

function ErrorComponent() {
  return <div>Oops! Something went wrong!</div>;
}

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

const RouterApp = () => {
  const queryClient = useQueryClient();
  const auth = useAuth();

  return <RouterProvider router={router} context={{ queryClient, auth }} />;
};

const RootApp = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <CurrencyProvider>
          <CountryProvider>
            <RouterApp />
            <TanStackRouterDevtools router={router} initialIsOpen={false} />
            <TanstackQueryDevtools initialIsOpen={false} />
          </CountryProvider>
        </CurrencyProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
};

// Render the app
const rootElement = document.getElementById("app")!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      <RootApp />
    </StrictMode>
  );
}
