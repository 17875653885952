import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { GraphWrapper } from "../elements/graphWrapper";
import { useBalanceGraph } from "../useGraph";
import { GraphLoading } from "../elements/graphLoading";
import { GraphError } from "../elements/graphError";
import { TimeseriesGraph } from "../basicGraphs/timeseries.graph";
import { GraphRangePicker, RangeData } from "../elements/graphRangePicker";
import { useCurrency } from "@/features/layouts/providers/currency-provider";

export const Balance: FC = () => {
  const { t } = useTranslation("graphs");
  const { currency } = useCurrency();
  const [range, setRange] = useState<RangeData>({});
  const { data } = useBalanceGraph({
    currency,
    to: range.to,
    from: range.from,
  });

  if (data.isLoading) return <GraphLoading />;
  if (!data.data || data.error) return <GraphError />;

  const { data: d } = data.data;

  const g = d.map((x) => ({
    name: x.d,
    value: Number(x?.v?.toFixed(2)),
  }));

  return (
    <GraphWrapper
      name={t("graphTitle.balance", { unit: currency.toUpperCase() })}
      unit={currency.toUpperCase()}
      buttons={<GraphRangePicker range={range} handleRangeChange={setRange} />}
      url={"/dashboard/graphs/balance"}
    >
      <TimeseriesGraph unit={currency.toUpperCase()} data={g} />
    </GraphWrapper>
  );
};
