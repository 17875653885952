import { useTranslation } from "react-i18next";
import { FC } from "react";
import { ErrorBoundary } from "@sentry/react";
import { graphsConfig } from "./graphs.config";

export const GraphsView: FC = () => {
  const { t } = useTranslation(["graphs", "errors"]);

  return (
    <div className="h-max w-full flex gap-4 flex-col">
      <div className="w-full">
        <h1 className="text-xl font-bold"> {t("allGraphs")}</h1>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 overflow-y-auto">
        {Object.keys(graphsConfig).map((el, id) => (
          <ErrorBoundary
            key={id}
            fallback={
              <div className="p-4 text-lg font-semibold flex w-full flex-col h-full items-center justify-center shadow-md rounded-lg">
                {t("failed_loading", { ns: "errors" })}
              </div>
            }
          >
            <div key={id}>{graphsConfig[el]}</div>
          </ErrorBoundary>
        ))}
      </div>
    </div>
  );
};
