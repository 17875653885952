import React from "react";
import * as Sentry from "@sentry/react";

export function initSentry(router) {
  process.env.NODE_ENV === "production" &&
    Sentry.init({
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
        Sentry.tanstackRouterBrowserTracingIntegration(router),
      ],
      environment: "production",
      tunnel: import.meta.env.VITE_SENTRY_TUNNEL ? "/api/sentry/tunnel" : null,
      tracesSampleRate: 1.0,
      tracePropagationTargets: [/^\/api/],
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
}

const TanStackRouterDevtools =
  process.env.NODE_ENV === "production"
    ? () => null
    : React.lazy(() =>
        import("@tanstack/router-devtools").then((res) => ({
          default: res.TanStackRouterDevtools,
        }))
      );
const TanstackQueryDevtools =
  process.env.NODE_ENV === "production"
    ? () => null
    : React.lazy(() =>
        import("@tanstack/react-query-devtools").then((res) => ({
          default: res.ReactQueryDevtools,
        }))
      );

export { TanStackRouterDevtools, TanstackQueryDevtools };
